import React from 'react'
import { Link } from 'gatsby'
import Seo from '../components/seo'
import SectionTitle from '../components/sectionTitle'
import Logo from '../components/logo'
// import NavItem from '../components/navItem'

const Success = () => (
  <>
    <Seo title={`Payment successful`} />
    <SectionTitle
      title={`Payment successful`}
      subtitle={`You'll get an e-mail with your purchase shortly`}
    />
    <section className="max-w-6xl mx-auto text-center my-12 px-12 sm:px-6">
      {/*<div className={`mb-6`}>*/}
      {/*  <div className="flex flex-wrap justify-center -mb-3">*/}
      {/*    <NavItem to={`/`}>Home</NavItem>*/}
      {/*    <NavItem to={`/info/faq/`}>FAQ</NavItem>*/}
      {/*    <NavItem to={`/info/support/`}>Support</NavItem>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <p className="text-gray-500 text-xs">
        Haven't received your product? Drop an e-mail to{' '}
        <a href="mailto:viktor@justboil.me" className="normal">
          viktor@justboil.me
        </a>
      </p>
    </section>
    <section className="flex justify-center mt-12 px-6">
      <Link to="/">
        <Logo />
      </Link>
    </section>
  </>
)

export default Success
